$theme-colors: (
  "primary": #b71c1c,
  "secondary": #212121
);

@font-face {
  font-family: globo;
  src: url(assets/fonts/SimplyRoundedBold.ttf) format("truetype");
}

$enable-responsive-font-sizes: true;

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css");
@import '~bootstrap/scss/bootstrap';